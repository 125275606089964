export default[
    
    {
      path: '/mitra_kalender_penjadwaan',
      name: '/mitra_kalender_penjadwaan',
      component: () => import('@/views/modul/mitra/kalender_penjadwaan/index.vue'),
      meta: {
        pageTitle: 'Kalender Penjadwalan Produksi',
        breadcrumb: [ 
          {
            text: 'Kalender Penjadwalan Produksi',
            active: true,
          },
        ],
      },
    },
      {
        path: '/mitra_umkm_peminjaman_alat',
        name: '/mitra_umkm_peminjaman_alat',
        component: () => import('@/views/modul/mitra/umkm_peminjaman_alat/index.vue'),
        meta: {
          pageTitle: 'Pengajuan Peminjaman Alat',
          breadcrumb: [ 
            {
              text: 'Pengajuan Peminjaman Alat',
              active: true,
            },
          ],
        },
    }, 
     
]