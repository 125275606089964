export default[
    
    {
      path: '/admin_alat_produksi',
      name: '/admin_alat_produksi',
      component: () => import('@/views/modul/admin/alat_produksi/index.vue'),
      meta: {
        pageTitle: 'Alat Produksi',
        breadcrumb: [ 
          {
            text: 'Alat Produksi',
            active: true,
          },
        ],
      },
    },
      {
        path: '/admin_peminjaman_alat',
        name: '/admin_peminjaman_alat',
        component: () => import('@/views/modul/admin/peminjaman_alat/index.vue'),
        meta: {
          pageTitle: 'Peminjaman Alat Produksi',
          breadcrumb: [ 
            {
              text: 'Peminjaman Alat Produksi',
              active: true,
            },
          ],
        },
    }, 
    {
        path: '/admin_jadwal_penggunaan_alat',
        name: '/admin_jadwal_penggunaan_alat',
        component: () => import('@/views/modul/admin/jadwal_penggunaan_alat/index.vue'),
        meta: {
          pageTitle: 'Jadwal Alat Produksi',
          breadcrumb: [ 
            {
              text: 'Jadwal Alat Produksi',
              active: true,
            },
          ],
        },
    },
    {
        path: '/admin_penggunaan_alat',
        name: '/admin_penggunaan_alat',
        component: () => import('@/views/modul/admin/laporan/penggunaan_alat/index.vue'),
        meta: {
          pageTitle: 'Laporan Penggunaan Alat',
          breadcrumb: [ 
            {
              text: 'Laporan Penggunaan Alat',
              active: true,
            },
          ],
        },
    },
    {
        path: '/admin_sewa_alat',
        name: '/admin_sewa_alat',
        component: () => import('@/views/modul/admin/laporan/sewa_alat/index.vue'),
        meta: {
          pageTitle: 'Sewa Alat',
          breadcrumb: [ 
            {
              text: 'Sewa Alat',
              active: true,
            },
          ],
        },
    },
]